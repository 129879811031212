<script setup lang="ts">
  import { useNProgress } from '@vueuse/integrations/useNProgress'
  import type { PloneDocument, PloneLocation } from '~/types/Plone'
  import useComponentMapping from '~/composables/useComponentMapping'
  import { useNuxtApp } from '#app'
  import { match } from 'ts-pattern'

  const { start, done } = useNProgress()
  const app = useNuxtApp()

  const props = withDefaults(defineProps<{
    locations?: PloneDocument[]
    location?: PloneLocation
    zoom?: number
  }>(), {
    zoom: 8,
  })

  const mapRef = ref()

  // delay map render to prevent issues with SSR
  const initialized = ref(false)
  onMounted(() => {
    setTimeout(() => {
      initialized.value = true
    }, 300)
  })

  const center = ref(props.location
    ? props.location
    : { lat: 49.7438, lng: 15.3387 })

  const getMarkerIcon = (item = null) => {
    const fillColor = match(item)
      .with({ competition_regularity: { token: 'regular' } }, () => '#57C653')
      .with({ competition_regularity: { token: 'reservation' } }, () => '#FF6D49')
      .with({ competition_regularity: { token: 'irregular' } }, () => '#000')
      .with({ competition_regularity: { token: 'unverified' } }, () => 'transparent')
      .with({ has_city_architect: 2 }, () => '#99999e')
      .otherwise(() => '#e63e14')

    const strokeColor = match(item)
      .with({ competition_regularity: { token: 'unverified' } }, () => '#57C653')
      .otherwise(() => '#fff')

    return {
      path: 'M16,1c7.122,0 13.092,4.975 14.621,11.636c0.248,1.082 0.379,2.208 0.379,3.364c-0,8.279 -6.721,15 -15,15c-8.279,0 -15,-6.721 -15,-15c-0,-8.279 6.721,-15 15,-15Z',
      fillColor,
      fillOpacity: 1,
      strokeColor,
      strokeWeight: 4,
      rotation: 0,
      scale: 1,
    }
  }

  const openedItem = ref(null)
  const isPopupOpened = ref(false)
  const hasOpenedItem = computed(() => !!openedItem.value)

  async function openPopup (item) {
    isPopupOpened.value = true
    start()
    openedItem.value = await app.$plone(item['@id'], { params: { show_images: 1 } })
    done()
  }

  function closePopup () {
    isPopupOpened.value = false
    openedItem.value = false
  }
</script>

<template>
  <div class="app-map">
    <GMapMap
      v-if="initialized"
      :style="{ width: '100%', height: '100%'}"
      :center="center"
      ref="mapRef"
      :options="{
        mapId: '71f3e6428898c546',
        mapTypeControl: false,
        streetViewControlOptions: false,
      }"
      :zoom="zoom">
      <GMapMarker
        v-if="location?.lat && location?.lng"
        :icon="getMarkerIcon()"
        :options="{ position: location }"/>
      <GMapCluster
        v-if="locations"
        zoom-on-click
        :styles="[
          {
            width: 42,
            height: 42,
            className: 'app-map__cluster app-map__cluster--sm',
          },
          {
            width: 64,
            height: 64,
            className: 'app-map__cluster app-map__cluster--md',
          },
          {
            width: 96,
            height: 96,
            className: 'app-map__cluster app-map__cluster--lg',
          },
        ]">
        <template v-for="item in locations">
          <GMapMarker
            v-if="item?.location?.lat && item?.location?.lng"
            :key="item['@id']"
            :title="item.title"
            :icon="getMarkerIcon(item)"
            clickable
            @click="openPopup(item)"
            :options="{ position: item.location }"/>
          </template>
      </GMapCluster>
    </GMapMap>
    <div
      v-if="isPopupOpened"
      v-motion-pop
      class="app-map__popup">
      <div class="app-map__popup-content">
        <component
          v-if="hasOpenedItem"
          :key="openedItem?.['@id']"
          :is="useComponentMapping(openedItem)"
          :data="openedItem"/>
        <template v-else>
          <skeleton-card/>
        </template>
        <app-button
          class="app-map__popup-close"
          circle
          @click="closePopup">
          <mdicon name="close"/>
        </app-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .app-map {
    height: 100%;
  }

  :deep() {
    & .vue-map-container {
      width: 100%;
      height: 100%;
    }

    & .app-map__cluster {
      border-radius: 50%;
      border: solid var(--gap-100) var(--c-white);
      text-align: center;
      color: var(--c-white);
      background: var(--c-primary);
      font-family: var(--font-family);
      box-shadow: var(--box-shadow);
    }

    & .app-map__cluster--sm {
      font-size: var(--font-size-400);
      line-height: 42px;
    }

    & .app-map__cluster--md {
      font-size: var(--font-size-500);
      line-height: 64px;
    }

    & .app-map__cluster--lg {
      font-size: var(--font-size-600);
      line-height: 96px;
    }
  }

  .app-map__popup {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    & :deep(.app-card) {
      max-width: 320px;
      box-shadow: var(--box-shadow);
    }
  }

  .app-map__popup-content {
    pointer-events: all;
    position: relative;
    width: clamp(280px, 5vw, 320px);
  }

  .app-map__popup-close {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    transform: translate(-50%, -50%);
  }
</style>
